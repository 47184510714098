import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

const StyledCalendarContainer = styled.div`
  max-width: 1500px;
  margin: auto;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-top: 10rem;
`;

const StyledCalendarHeader = styled.h2`
  color: #333;
  text-align: center;
  margin-right: 5rem;
  font-size: 37px;
`;

const StyledCalendar = styled(Calendar)`
  font-size: 14px;

  .rbc-month-view {
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .rbc-event, .rbc-day-slot .rbc-background-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background-color: #3174ad;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }

  .rbc-header {
    background-color: #800000;
    color: #ffffff;
    font-weight: bold;
    padding: 10px;
    border-bottom: 2px solid #ffffff;
  }

  .rbc-day-bg {
    background-color: #f8f9fa;
  }

  .rbc-today {
    background-color: #4285f4;
    color: #ffffff;
  }

  .rbc-agenda-view {
    border-top: 2px solid #4285f4;
  }

  .rbc-agenda-date-cell,
  .rbc-agenda-time-cell {
    font-size: 16px;
    color: #333;
  }

  .rbc-agenda-event-cell {
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    background-color: #4285f4;
    color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s;

    a {
      color: #ffffff;
      text-decoration: none;
    }

    &:hover {
      background-color: #3367d6;
    }
  }
`;

const EventModal = ({ show, onHide, event }) => {

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{event.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: '1' }}>
                        <p>
                            <b>Event Date:</b> {moment(event.start).format('MMMM Do YYYY')}
                        </p>
                        <p>
                            <b>Description:</b>
                            <span dangerouslySetInnerHTML={{ __html: event.description }} />
                        </p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const EventCalendar = () => {
    const localizer = momentLocalizer(moment);
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        fetch(`http://testloreto.cristolive.org/api/news/province/1`)
            .then((response) => response.json())
            .then((data) => {
                const formattedEvents = data.data.map((event) => ({
                    title: event.name,
                    start: moment(event.date, 'DD-MM-YYYY').toDate(),
                    end: moment(event.date, 'DD-MM-YYYY').toDate(),
                    description: event.description,
                    events: true,
                }));
                setEvents(formattedEvents);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    const handleEventClick = (event) => {
        setSelectedEvent(event);
    };

    const closeModal = () => {
        setSelectedEvent(null);
    };

    const CustomEvent = ({ event }) => {
        const currentDate = moment().startOf('day');
        const isEventToday = moment(event.start).isSame(currentDate, 'day');

        return (
            <div>
                {event.events && isEventToday ? (
                    <div>
                        <div>{event.title}</div>
                        <img src={'assets/images/home/new.gif'} alt="Event" height="60" />
                    </div>
                ) : (
                    <div>{event.title}</div>
                )}
            </div>
        );
    };



    return (
        <>
            <StyledCalendarContainer>
                <StyledCalendarHeader>Events Calendar</StyledCalendarHeader>
                <StyledCalendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 1000 }}
                    onSelectEvent={handleEventClick}
                    eventLimit={1}
                    components={{
                        event: CustomEvent, // Use the custom event component
                    }}
                />
            </StyledCalendarContainer>
            <br />
            {selectedEvent && (
                <EventModal show={!!selectedEvent} onHide={closeModal} event={selectedEvent} />
            )}
        </>
    );
};

export default EventCalendar;